.bg {
  background-image: url("../../assets/images/BG.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-clip: padding-box;
}

.height {
  height: 100dvh;
}
