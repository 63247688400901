.details-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-height: 80vh;
  overflow-y: auto;
  width: 600px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  outline: none;
}

.details-modal:focus {
  outline: none;
  border: none;
}

.modal-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.modal-item {
  margin: 5px 0;
  padding: 5px;
  background-color: #f0f0f0;
  border-radius: 4px;
}
