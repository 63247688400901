.hexagon-graph {
  display: flex;
  justify-content: center;
  padding-block: 50px;
  position: relative;
  z-index: 1;
}

.hexagon-graph::before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url("../../../assets/images/world_map.png");
  background-size: cover;
  background-repeat: no-repeat;
  filter: grayscale(0%) brightness(100%) contrast(0%) opacity(0.1);

  z-index: 0;
  pointer-events: none;
}

.hexagon-center {
  display: flex;
}

.hexagon-center .hexagon-card-wrapper {
  align-items: center;
  position: relative;
  z-index: 3;
}

.hexagon-side {
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1;
}

.hexagon-side .hexagon-card-wrapper {
  display: flex;
  align-items: center;
  margin-top: auto;
  position: relative;
  gap: 2px;
  min-height: 295px;
}

.hexagon-side:first-of-type .hexagon-card-wrapper {
  flex-direction: row-reverse;
}

.hexagon-side.left .hexagon-card-wrapper::before,
.hexagon-side.right .hexagon-card-wrapper::before {
  content: "";
  position: absolute;
  width: 1px;
  background-color: #ed545c;
  top: 50%;
  height: calc(50% - 60px);
}

.hexagon-side.left .hexagon-card-wrapper::before {
  left: 50%;
  transform: translateX(50%);
}

.hexagon-side.right .hexagon-card-wrapper::before {
  right: 50%;
  transform: translateX(-50%);
}

.hexagon-side.left:first-of-type .hexagon-card-wrapper::before {
  left: unset;
  right: 61px;
  transform: rotate(-45deg);
  top: 55%;
  height: 100%;
  transform: translateY(-50%) rotate(-50deg);
}

.hexagon-side.left .hexagon-card-wrapper:last-of-type::before {
  left: unset;
  right: 31px;
  top: 34%;
  height: 100%;
  transform: translateY(-50%) rotate(48deg);
}

.hexagon-side.right .hexagon-card-wrapper::before {
  left: 50px;
  right: unset;
  top: 55%;
  height: 95%;
  transform: translateY(-50%) rotate(45deg);
}

.hexagon-side.right .hexagon-card-wrapper:last-of-type::before {
  transform: translateY(-50%) rotate(-45deg);
  height: 100%;
  left: 36px;
  top: 39%;
}

.hexagon-side.right .hexagon-list::before {
  left: -26px;
  right: unset;
  width: 20px;
}

.hexagon-side.left .hexagon-list::before {
  right: -20px;
  left: unset;
  width: 20px;
}

@media (max-width: 1024px) {
  .hexagon-side.left:first-of-type .hexagon-card-wrapper::before {
    top: 70%;
    right: 17px;
    height: 83%;
  }

  .hexagon-side.right .hexagon-card-wrapper::before {
    left: -10px;
    right: unset;
    top: 75%;
    height: 95%;
  }

  .hexagon-side.right .hexagon-card-wrapper:last-of-type::before {
    left: 33px;
    top: 32%;
  }

  .hexagon-side.left .hexagon-card-wrapper:last-of-type::before {
    transform: translateY(-50%) rotate(46deg);
    top: 37%;
    right: 39px;
  }
}

@media (max-width: 768px) {
  .hexagon-graph {
    flex-direction: column;
    padding-block: 10px;
  }

  .hexagon-side.left .hexagon-card-wrapper::before,
  .hexagon-side.right .hexagon-card-wrapper::before {
    display: none;
  }

  .hexagon-side {
    flex-direction: row;
    gap: 12px;
  }

  .hexagon-card {
    height: 140px;
    width: 170px;
    margin: 0;
  }

  .hexagon-center {
    justify-content: center;
    order: 1;
  }

  .hexagon-side.left {
    order: 2;
  }

  .hexagon-side.right {
    order: 3;
  }
}
