.ownership-structure .MuiGrid-container {
  background-color: #aa1d23 !important;
}

.ownership-structure h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  margin-top: 0;
}

.ownership-structure-table.MuiTableContainer-root {
  margin-top: 0 !important;
}
