.bg {
  background-image: url("../../assets/images/BG.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.modal_show {
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.modal_hide {
  display: none;
}
