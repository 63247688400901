@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Raleway:wght@300;400;500;600;700&family=Roboto+Flex:wght@300;400;500;600;700&family=Ubuntu:wght@300;400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    scroll-behavior: smooth;
    font-family: "Montserrat", sans-serif;
  }
  * {
    box-sizing: border-box;
    font-size: clamp(0.8rem, calc(0.5rem + 0.4vw), 1.2rem);
  }
}

@layer components {
  .htmlRender h1 {
    @apply text-2xl font-bold my-2 text-gray-800;
  }

  .htmlRender h2 {
    @apply text-xl font-bold my-2 text-gray-800;
  }

  .htmlRender h3 {
    @apply text-lg font-bold my-2 text-gray-800;
  }

  .htmlRender ul {
    @apply list-disc list-inside pl-5 my-2;
  }

  .htmlRender li {
    @apply my-1 text-gray-800;
  }
}

body,
html {
  margin: 0;
  padding: 0;
}

* {
  font-family: "Montserrat", sans-serif !important;
  box-sizing: border-box;
}

.borderb::after {
  position: absolute;
  content: "";
  height: 2px;
  background-color: #aa1d23;
  width: 90%;
  bottom: -2.5px;
  left: 5%;
}

.separate > a:not(:last-child)::after {
  content: "|";
  margin: 0 0.3rem;
  color: white;
  display: inline-block;
}

.separate2 > a:not(:last-child)::after {
  content: "|";
  margin: 0 0.3rem;
  color: black;
  display: inline-block;
}

.separate3::after {
  content: "";
  height: 80%;
  background-color: rgba(255, 255, 255, 0.3);
  width: 1px;
  position: absolute;
  right: -65px;
  top: 40px;
}

.searchtable th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.searchtable th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: #2b343b;
}
::-webkit-scrollbar-thumb {
  background: #ed545c;
}

.diagramtable th:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.diagramtable th:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

@media (max-width: 1024px) {
  .connector::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 2%;
    background-color: #e7e7e7;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
}

@media (min-width: 1025px) {
  .connector::after {
    content: "";
    position: absolute;
    height: 15px;
    width: 100%;
    background-color: #e7e7e7;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
}

.fontSize tspan {
  font-size: 0.85rem !important;
}

.swiper-pagination {
  bottom: 0 !important;
}

.swiper-pagination-bullet-active {
  background-color: #ed545c !important;
}

.swiper-button-prev {
  left: 0 !important;
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-button-prev,
.swiper-button-next {
  top: 50%;
  transform: translateY(-35%);
  color: #ed545c !important;
}

.MuiStack-root > :last-child {
  margin-bottom: 0 !important;
}

.guideline-target-element {
  position: relative;
}

& .MuiOutlinedInput-notchedOutline legend span {
  font-size: 0.75rem !important;
}

.management-table .MuiGrid-container {
  background-color: #aa1d23 !important;
}

.other-important-informations table thead tr th:first-of-type,
.other-important-informations table tbody tr td:first-of-type {
  text-align: left !important;
}

.filter-modal-button:not(:hover) {
  background-color: #202831 !important;
}
