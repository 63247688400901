.hexagon-card {
  height: 180px;
  width: 210px;
  flex-shrink: 0;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  background-color: #e9eef2;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  transition: transform 0.3s ease;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  transform: perspective(1px) translateZ(0);
  backface-visibility: hidden;
  position: relative;
  z-index: 10;
}

.hexagon-card:hover {
  transform: scale(1.048);
}

.hexagon-card.main {
  height: 250px;
  width: 280px;
  background-color: #ed545c;
  color: #fff;
  pointer-events: none;
}

.hexagon-card.main .hexagon-title {
  font-size: 1rem;
}

.hexagon-content {
  text-align: center;
}

.hexagon-title {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.hexagon-card.main .hexagon-title {
  font-size: 14px;
}

.hexagon-count {
  font-weight: bold;
}

.hexagon-list {
  list-style: none;
  padding: 0;
  margin: 5px 0;
  position: relative;
  width: 100%;
}

.hexagon-item {
  background-color: #d3e6f5;
  color: #000;
  margin: 3px 0;
  padding: 4px 6px;
  border-radius: 3px;
  font-size: 0.7rem;
  position: relative;
  width: 100%;
}

.hexagon-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hexagon-list::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 50%;
  width: 8px;
  height: 1px;
  background-color: #ed545c;
  transform: translateY(-50%);
}

.hexagon-side.left .hexagon-list::after,
.hexagon-side.right .hexagon-list::after {
  content: "";
  position: absolute;
  top: 50%;
  bottom: 0;
  width: 1px;
  transform: translateY(-50%);
  background-color: #ed545c;
  height: calc(100% - 27px);
}

.hexagon-side.left .hexagon-list::after {
  right: -6px;
}

.hexagon-side.right .hexagon-list::after {
  left: -6px;
}

.hexagon-item::before {
  content: "";
  position: absolute;
  top: 50%;
  height: 1px;
  width: 5px;
  background-color: #ed545c;
  transform: translateY(-50%);
}

.hexagon-side.left .hexagon-item::before {
  right: -5px;
}

.hexagon-side.right .hexagon-item::before {
  left: -5px;
}

.hexagon-side.left .hexagon-item:first-of-type::after {
  content: "";
  background-color: white;
  z-index: 10;
  position: absolute;
  height: 50%;
  top: -1px;
  right: -8px;
  width: 5px;
}

.hexagon-side.left .hexagon-item:last-of-type::after {
  content: "";
  background-color: white;
  z-index: 10;
  position: absolute;
  height: 50%;
  bottom: -1px;
  right: -8px;
  width: 5px;
}

.hexagon-side.right .hexagon-item:first-of-type::after {
  content: "";
  background-color: white;
  z-index: 10;
  position: absolute;
  height: 50%;
  top: -1px;
  width: 5px;
  left: -8px;
}

.hexagon-side.right .hexagon-item:last-of-type::after {
  content: "";
  background-color: white;
  z-index: 10;
  position: absolute;
  height: 50%;
  bottom: -1px;
  width: 5px;
  left: -8px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .hexagon-card {
    margin: 0;
    height: 140px;
    width: 170px;
  }

  .hexagon-card.main {
    height: 190px;
    width: 220px;
  }
}

@media (max-width: 1024px) {
  .hexagon-list-wrapper {
    display: none;
  }
}
