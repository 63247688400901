.related-companies h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.related-companies-table {
  margin-top: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.dynamic-filter-search {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.search-bar {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 10px;
}

.pagination {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.accordion {
  margin-top: 10px;
}

.accordion-summary {
  font-weight: bold;
  background-color: #e0e0e0;
}

.spinner {
  margin: 10px auto;
}

.related-companies-table .MuiGrid-container {
  background-color: #aa1d23 !important;
}
