.company-daughters-table {
  margin-top: 20px;
}

.company-daughters-table {
  margin-top: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.company-daughters-table .MuiGrid-container {
  background-color: #aa1d23 !important;
}

.company-daughters h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}
